/* eslint-disable */
import React, { useEffect, useState } from "react";
import "./styles/custom/bootstrap.css";
import "./styles/custom/stack-interface.css";
import "./styles/custom/socicon.css";
import "./styles/custom/theme.css";
import "./styles/custom/custom.css";
import "./styles/custom/flickity.css";


import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
// import * as s from "./styles/globalStyles";
// import styled from "styled-components";
// import { create } from "ipfs-http-client";

// export const StyledButton = styled.button`
//   padding: 8px;
// `;

// Or for ES2015 module
import Flickity from 'react-flickity-component'

const flickityOptions = {
  cellSelector: '.sliderBoxes',
  initialIndex: 2,
  autoPlay: true,
  cellAlign: 'left',
  wrapAround: true,
  prevNextButtons: false

}

var mintNumber = 1;
var totalMinted = 0;

var currentD = new Date();
if (currentD > Date.parse('2021-11-21T01:00:00.000+08:00')) {
  var open = 1;
} else {

  var open = 0;

}

function Carousel() {
  return (
    <Flickity
      className={'carousel'} // default ''
      elementType={'div'} // default 'div'
      options={flickityOptions} // takes flickity options {}
      disableImagesLoaded={false} // default false
      reloadOnUpdate // default false
      static // default false
    >
      <div class="col-md-3 col-6 sliderBoxes">
        <img class="border--round" alt="luckymahjongnft" src="./img/1.png" />
      </div>
      <div class="col-md-3 col-6 sliderBoxes">
        <img class="border--round" alt="luckymahjongnft" src="./img/2.png" />
      </div>
      <div class="col-md-3 col-6 sliderBoxes">
        <img class="border--round" alt="luckymahjongnft" src="./img/3.png" />
      </div>
      <div class="col-md-3 col-6 sliderBoxes">
        <img class="border--round" alt="luckymahjongnft" src="./img/4.png" />
      </div>
      <div class="col-md-3 col-6 sliderBoxes">
        <img class="border--round" alt="luckymahjongnft" src="./img/5.png" />
      </div>
      <div class="col-md-3 col-6 sliderBoxes">
        <img class="border--round" alt="luckymahjongnft" src="./img/6.png" />
      </div>
    </Flickity>
  );
}


class InputControl extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      inputValue: 1,
    }
    this.changeState = this.changeState.bind(this)
  }
  changeState(event) {
    this.setState({ inputValue: event.target.value })
    mintNumber = event.target.value;
  }

  render() {
    return (
      <div class="col-7">
        <input type="number" value={this.state.inputValue} onChange={this.changeState} />
      </div>
    )
  }
}

class GetTokenSupply extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      item: []
    };
  }

  componentDidMount() {
    fetch("https://api.etherscan.io/api?module=stats&action=tokensupply&contractaddress=0xAF0EB6AB3ee9C69E344e2f28ee22965eB7003B7d&apikey=TWYM7DFEXQFRVJU43TG17M6QBCY9QTQH65")
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            item: result
          });
          totalMinted = result.result;
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  render() {
    const { error, isLoaded, item } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
        <span class="text-gold">{item.result}</span>
      );
    }
  }
}

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  // const data = useSelector((state) => state.data);
  const [feedback, setFeedback] = useState("Maybe it's your lucky day!");
  const [claimingNft, setClaimingNft] = useState(false);


  const claimNFTs = (_amount) => {
    if (claimingNft) {

    } else {
      setClaimingNft(true);
      blockchain.smartContract.methods.mint(_amount).send({
        from: blockchain.account,
        value: blockchain.web3.utils.toWei((0.03 * _amount).toString(), "ether"),
      }).once("error", (err) => {
        console.log(err);
        setFeedback(err);
        setClaimingNft(false);
      }).then((receipt) => {
        totalMinted = parseInt(totalMinted) + _amount;
        console.log(totalMinted);
        setFeedback("Minted success!");

        setClaimingNft(false);
      })
    }

  }



  // const getMintedNumber = () => {
  //   if (blockchain.account !== "" && blockchain.smartContract !== null) {
  //     blockchain.smartContract.methods.totalSupply().call(function (err, res) {
  //       if (!err) {
  //         console.log(res);
  //         totalMinted = res;
  //       } else {
  //         console.log(err);
  //       }
  //     })
  //   }
  // };



  useEffect(() => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  }, [blockchain.smartContract, dispatch]);

  return (

    <div>
      <div class="nav-container bg--dark">
        <div class="bar bar--sm visible-xs">
          <div class="container">
            <div class="row">
              <div class="col-12 col-md-2">
                <a href="/">
                  <img class="logo logo-dark" alt="logo" src="./img/logo_black.png" />
                  <span class="logo-text">Lucky Mahjong NFT</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <nav id="menu1" class="bar bar--sm bar-1 hidden-xs bar--absolute">
          <div class="container">
            <div class="row">
              <div class="col-md-6 hidden-xs">
                <div class="bar__module">
                  <a href="/">
                    <img class="logo logo-dark" alt="logo" src="./img/logo_black.png" />
                    <span class="logo-text">Lucky Mahjong NFT</span>
                  </a>
                </div>
              </div>
              <div class="col-md-6 text-right text-left-xs text-left-sm">
                <div class="bar__module">
                  {blockchain.account === "" || blockchain.smartContract === null ? (
                    <a class="btn btn--sm btn--primary type--uppercase" href="#" onClick={(e) => {
                      e.preventDefault();
                      dispatch(connect());
                    }}>
                      <span class="btn__text">
                        Connect Wallet
                      </span>

                      {blockchain.errorMsg !== "" ? (
                        <p class="text-danger">{blockchain.errorMsg}</p>
                      ) : null}

                    </a>
                  ) : (
                    <a class="btn btn--sm btn--primary type--uppercase" href="#" onClick={(e) => {
                      e.preventDefault();
                    }}>
                      <span class="btn__text">
                        {blockchain.account.substring(0, 4)}...{blockchain.account.slice(-4)}
                      </span>

                      {blockchain.errorMsg !== "" ? (
                        <span class="text-danger">{blockchain.errorMsg}</span>
                      ) : null}

                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <div class="main-container bg--dark">
        <section class="cover height-100 text-center imagebg space--lg unpad--bottom top-bg " >
          <div class="container ">
            <div class="row justify-content-around">
              <div class="col-md-6 text-center">
                <div class="mt--2">
                  <h1>Good luck in your crypto wallet.</h1>
                  <p class="lead">
                    Which is your lucky numbers?
                  </p>
                  <h3 class="text-gold mb-0">0.03 ETH / 1 NFT</h3>
                  {feedback == "Minted success!" ? (
                    <h3>{totalMinted}/6666 Minted</h3>
                  ) : (
                    <h3><GetTokenSupply />/6666 Minted</h3>
                  )}

                  {open === 0 ? (
                    <div>
                      <h4 class="text-gold"> Launch time <a class="btn-calendar" href="http://www.google.com/calendar/render?
action=TEMPLATE
&text=LuckyMahjongNFT_Minting
&dates=20211121T010000/20211121T020000
&ctz=Asia/Taipei
&location=https://luckymahjong.club
&details=LuckyMahjongNFT_Minting%0Ahttps%3A%2F%2Fluckymahjong.club%2F
&trp=false
&sprop=
&sprop=name:"
                        target="_blank" rel="nofollow">Add to my calendar</a></h4>
                      <h3 class="text-gold timer close">21 Nov. 01: 00 <small>UTC+8</small></h3>

                    </div>
                  ) : null}
                  {open > 0 && (blockchain.account === "" || blockchain.smartContract === null) ? (

                    <a class="btn btn--sm btn--primary type--uppercase" href="#" onClick={(e) => {
                      e.preventDefault();
                      dispatch(connect());
                    }}>
                      <span class="btn__text">
                        Connect Wallet
                      </span>

                      {blockchain.errorMsg !== "" ? (
                        <p class="text-danger">{blockchain.errorMsg}</p>
                      ) : null}

                    </a>
                  ) : (
                    open > 0 ? (
                      <div>

                        {blockchain.errorMsg !== "" ? (
                          <span class="text-danger">{blockchain.errorMsg}</span>
                        ) : null
                        }
                        <div class="row pb-5">
                          <InputControl />
                          <div class="col-5 pl-0">
                            <a class="btn btn--lg btn--primary type--uppercase btn-mint" href="#" disabled={claimingNft ? 1 : 0} onClick={(e) => {
                              e.preventDefault(); claimNFTs(mintNumber);
                            }}>
                              <span class="btn__text">
                                {claimingNft ? "Loading..." : "MINT"}
                              </span>

                              {blockchain.errorMsg !== "" ? (
                                <span class="text-danger">{blockchain.errorMsg}</span>
                              ) : null}

                            </a>

                          </div>
                          <div class="text-center">{feedback}</div>
                        </div>
                      </div>
                    ) : null
                  )}
                </div>
              </div>
              <div class="col-md-6 col-lg-5">
                <img class="pt-5 pt-md-0" alt="Image" src="./img/desk.png" />
              </div>
            </div>
          </div>
        </section>
        <section class="pt-0  sec-carousel">
          <div class="container pt-0">
            <Carousel />
          </div>
        </section>

        <section >
          <div class="container">
            <div class="row bg--dark">

              <div class="col-md-6 col-12 mb-5">
                <h2>6666 unique Mahjong sets.</h2>
                <h3>For colletor who needs good luck.</h3>
                <p>Lucky mahjong is a collection of 6666 mahjong sets that are computer generated. Each set has three mahjong tiles with random color and pattern. There are total 34 different patterns and 16 colors. Every NFT is unique and blessed. You can own your lucky numbers and pattern in your blockchain wallet. Hope this can bring you luck and fortunes in the world of nft.</p>

              </div>
              <div class="col-md-6 col-12 order-md-0">
                <img alt="Image" src="./img/mahjong.gif" />
              </div>
            </div>
          </div>
        </section>

        <section>
          <div class="container">
            <div class="row bg--dark justify-content-center">
              <div class="text-center col-12">
                <h2>Road Map</h2>
              </div>

              <div class="col-md-4 col-12 order-md-1">
                <img class="d-md-block d-none" alt="Image" src="./img/index_bg_icons.png" />
                <img class="d-md-block d-none" alt="Image" src="./img/index_bg_icons2.png" />
                <img class="d-md-block d-none" alt="Image" src="./img/index_bg_icons3.png" />
              </div>
              <div class="col-md-6 col-12 order-md-0">

                <h3><img alt="Image" class="number_dot" src="./img/dot1.png" /> Lunch NFT</h3>
                <p>Lucky Mahjong NFT is designed, planned, coded and marketing runed by Doris who is a NFT designer in Taiwan.The hope is to bring a unique creation on the nft market, which can at the same time bring good mood and confidence to collectors in the nft market.6666 nfts will be luched at November 2021, and fans can mint them on the official website.All works will be revealed one week after launch.</p>
                <h3 class="mt-3"><img alt="Image" class="number_dot" src="./img/dot2.png" />  Lucky Money Drop</h3>
                <p>In order to thank the members for their support, lucky mahjong holders numbered 1~1000 will get lucky red envelope nft for free.And when sales reach 50% and 100%, surprise ETH will drop to lucky mahjong members' wallet .</p>
                <h3 class="mt-3"><img alt="Image" class="number_dot" src="./img/dot3.png" />  NFT discover & sharing website "Mahjong Table"</h3>
                <p>We have seen the rapid growth of the NFT market, so we hope that the launch of a good NFT classification platform can help artists and collectors to match, in addition to helping good works of art more easily exposed, and making it easier for nft lovers to find favorite collections.Lucky Mahjong NFT will be a VIP ticket on the platform, used for advertising and exclusive NFT analysis and promotion.</p>
                <h3 class="mt-3"><img alt="Image" class="number_dot" src="./img/dot4.png" />  Community keeps good luck</h3>
                <p>We value every member who likes lucky mahjong.The lucky mahjong team will continue to run the community in the future, and continue to bring more lucky projects to everyone, and look forward to bring you more luck and joy on the road of nft investment.</p>
              </div>

            </div>
          </div>
        </section>
        <section class="bg--black">
          <div class="container">
            <div class="row ">

              <div class="col-md-3 order-md-1 col-8">
                <img alt="Image" src="./img/artist.png" />
              </div>
              <div class="col-md-9 order-md-0">
                <h2>Team</h2>
                <h3 class="font-display">Doris Liao</h3>
                <p>I'm a  nft collectors and artist comes from Taiwan and also a web developer who runs a startup company of ecommerce website, <a href="https://wwww.hiinpay.com" target="_blank">HiiNpay</a>. This project is designed, plained and coded by myself. Welcome all kind of collaboration, please contact <a href="doris@hiinmusic.com">doris@hiinmusic.com</a>. </p>
              </div>
            </div>
          </div>
        </section>
        <footer class="text-center-xs space--xs ">
          <div class="container">
            <div class="row">
              <div class="col-md-7">
                <ul class="list-inline">
                  <li>
                    <a href="#">
                      {/* <span class="h6 type--uppercase">Terms</span> */}
                    </a>
                  </li>
                  <li>
                    <a href="https://etherscan.io/address/0xaf0eb6ab3ee9c69e344e2f28ee22965eb7003b7d">
                      <span class="h6 type--uppercase">Contract</span>
                    </a>
                  </li>
                </ul>
                <ul class="social-list list-inline list--hover">
                  <li>
                    <a href="https://twitter.com/LuckyMahjongNFT" target="_blank">
                      <i class="socicon socicon-twitter icon icon--xs"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/luckymahjongnft/" target="_blank">
                      <i class="socicon socicon-instagram icon icon--xs"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-md-5 text-right text-center-xs">

              </div>
            </div>
            <div class="row">
              <div class="col-md-7">
                <span class="type--fine-print">&copy;
                  <span class="update-year"></span> LuckyMahjongNFT</span>
              </div>
            </div>
          </div>
        </footer>
      </div>
      <a class="back-to-top inner-link" href="#start" data-scroll-class="100vh:active">
        <i class="stack-interface stack-up-open-big"></i>
      </a>
    </div>
  );
}

export default App;
